import Zone from "../Zone"
import json from "../t4/Development.json"

class Development implements Zone {
	codename = "development"
	accepts: (url: string) => boolean = (url) =>
		url.toLowerCase().indexOf("localhost") !== -1 ||
		url.toLowerCase().endsWith(".multi")
	airbrakeConfig = {
		projectId: 172748,
		projectKey: "8cfb83392b51f8a4c5423a26fdcec3e3"
	}
	allowsUserIdOverride = true

	settings = json
}

export default Development
