import { INotice, IOptions, Notifier } from "@airbrake/browser"
import { jsonifyNotice } from "@airbrake/browser/dist/jsonify_notice"
import { zoneRegistry } from "mco-zone-configurations"
import { makeDebounceFilter } from "@airbrake/browser/dist/filter/debounce"

const onerror = window.onerror

class McoNotifier extends Notifier {
	constructor(opt: IOptions) {
		opt = {
			...opt,
			keysBlocklist: [
				...(opt.keysBlocklist || []),
				"Authorization",
				"data",
				"username",
				"email",
				"name",
				"firstName",
				"lastName",
				"dateOfBirth",
				"nhsNumber",
				"chiNumber"
			]
		}
		super(opt)
		window.removeEventListener(
			"unhandledrejection",
			this.onUnhandledrejection
		)
		window.onerror = onerror
		this._scope._historyMaxLen = 200
	}

	protected override onUnhandledrejection(e: any): void {}

	// Override maxLength for notice
	override _sendNotice(notice: INotice): Promise<INotice> {
		let body = jsonifyNotice(notice, {
			keysBlocklist: this._opt.keysBlocklist,
			maxLength: 256000
		})
		if (this._opt.reporter) {
			if (typeof this._opt.reporter === "function") {
				return this._opt.reporter(notice)
			} else {
				console.warn("airbrake: options.reporter must be a function")
			}
		}

		let req = {
			method: "POST",
			url: this._url,
			body
		}
		return this._requester(req)
			.then((resp) => {
				notice.id = resp.json.id
				notice.url = resp.json.url
				return notice
			})
			.catch((err) => {
				notice.error = err
				return notice
			})
	}

	getHistoryLength = () => this._scope._history.length

	reduceHistoryLength = () => {
		const historyLength = this.getHistoryLength()
		if (historyLength === 0) {
			return
		}
		const newHistoryLength = -Math.floor(historyLength * 0.2)
		this._scope._history.splice(newHistoryLength)
	}

	debounceFilter = makeDebounceFilter()
	matchesDebounceFilter = (filter: Function) => {
		const debounceFilterValue = this.debounceFilter
			.toString()
			.replaceAll(/\s+/g, "")
		const filterValue = filter.toString().replaceAll(/\s+/g, "")

		return debounceFilterValue === filterValue
	}
	removeDebounceFilter = () =>
		(this._filters = this._filters.filter(
			(f, i) => !this.matchesDebounceFilter(f)
		))
	restoreDebounceFilter = () =>
		(this._filters = [...this._filters, this.debounceFilter])

	async notify(err: any): Promise<INotice> {
		let result: INotice = undefined!
		let count = 0
		while (count++ < 5) {
			this.removeDebounceFilter()
			try {
				result = await super.notify(err)
				if (!result.error) {
					return result
				}
				throw result
			} catch (notifyErr) {
				const historyLength = this.getHistoryLength()
				if (historyLength === 0) {
					console.error(
						"Unable to notify airbrake of error",
						notifyErr
					)
					return result
				}
				this.reduceHistoryLength()
			} finally {
				this.restoreDebounceFilter()
			}

			if (zoneRegistry.isDevelopment()) {
				throw result
			}
		}
		console.error("Unable to send error to airbrake")
		throw result
	}
}

const zone = zoneRegistry.get()

const notifier = new McoNotifier({
	projectId: zone.airbrakeConfig.projectId,
	projectKey: zone.airbrakeConfig.projectKey
})

export default notifier
