import notifier from "../components/Errors/Notifier"

let sentErrors: string[] = []

window.addEventListener("vite:preloadError", (event) => {
	const message = (event as any)?.payload?.message

	if (sentErrors.find(message)) {
		console.error("Error during preload (duplicate)", event)
		return
	}

	sentErrors.push()
	console.error("Error during preload", event)

	notifier.notify({
		error: event
	})
})
