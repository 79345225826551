import "./unsupportedBrowserRedirector.js"

import "react-app-polyfill/stable"
import "classlist.js"
import "./polyfills/EmptyFinalizationRegistryPolyfill"
import "./polyfills/viteErrorHandler"

import { createRoot } from "react-dom/client"
import * as serviceWorker from "./serviceWorker"
import { lazyRetry } from "mco-typescript-core"

const AppSelector = lazyRetry(() => import("./AppSelector"))

// if (
// 	import.meta.env.NODE_ENV !== "production" &&
// 	import.meta.env.VITE_APP_AXE === "true"
// ) {
// 	const axe = require("@axe-core/react")
// 	axe(React, ReactDOM, 1000, {})
// }

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(<AppSelector />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
