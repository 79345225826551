import { detect } from "detect-browser"
import { DateTime } from "luxon"

export const browserTests = [
	{
		name: "Internet Explorer",
		test: (browser) => browser.name === "ie",
		fromDate: DateTime.fromISO("2020-01-01")
	},
	{
		name: "Safari 12",
		test: (browser) =>
			browser.name === "safari" && /^12./.test(browser.version),
		fromDate: DateTime.fromISO("2023-06-01")
	}
]

function isUnsupportedBrowser() {
	const browser = detect()

	if (!browser) {
		console.warn("Unable to check browser is supported")
		return false
	}

	const test = browserTests.find((x) => x.test(browser))
	if (!test) {
		return false
	}

	return DateTime.utc() > test.fromDate
}

function redirectToUnsupportedBrowserPage() {
	console.debug(
		"This is an unsupported browser. Redirecting to the unsupported browser page"
	)
	window.location.href = "/unsupported-browser-warning.html"
	throw Error(
		"This browser in not supported. Please upgrade to a modern browser."
	)
}

if (isUnsupportedBrowser()) {
	redirectToUnsupportedBrowserPage()
}
