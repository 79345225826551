import Zone from "../Zone"
import json from "../t4/Test.json"

class Test implements Zone {
	codename = "test"
	accepts: (url: string) => boolean = (url) =>
		url.toLowerCase().indexOf(".testm.co") !== -1
	airbrakeConfig = {
		projectId: 172743,
		projectKey: "63fd6a4656714bb430b860bdcf9cc032"
	}
	allowsUserIdOverride = true

	settings = json
}

export default Test
