if (!window.FinalizationRegistry) {
	class FinalizationRegistry {
		// eslint-disable-next-line no-useless-constructor
		constructor(error) {}
		register(target, heldValue, unregisterToken) {}
		unregister(unregisterToken) {}
	}

	window.FinalizationRegistry = FinalizationRegistry
}
