import Zone from "../Zone"
import json from "../t4/Production.json"

class Production implements Zone {
	codename = "production"
	accepts: (url: string) => boolean = (url) =>
		url.toLowerCase().indexOf(".myclinicaloutcomes.") !== -1
	airbrakeConfig = {
		projectId: 172739,
		projectKey: "9c55c94ed246ef682e2d61b76551e8fe"
	}
	allowsUserIdOverride = false

	settings = json
}

export default Production
